import { toast } from "react-toastify";
import axios from 'axios';
import ScanResults from "./ScanResults";
import ScheduleAutomationModal from "./ScheduleAutomationModal";
import "../../../assets/css/common.css";
import { useMediaQuery } from 'react-responsive'
import Diagnostic from "./Cards/Diagnostic";
import Calibration from "./Cards/Calibration";
import { CustomDialog } from "react-st-modal";
import { useNavigate } from "react-router-dom";
import SolventReference from "./Cards/SolventReference";
import React, {useState, useEffect, useCallback, useRef} from "react";
// import { getDevices } from "../../../views/shared/devices";
import SelectYourHardware from "./Cards/SelectYourHardware";
import { CButton, CCol, CContainer, CRow, CFormSwitch, CModal, CModalHeader, CModalTitle, CModalBody, CModalFooter } from "@coreui/react";
import jwtInterceoptor from "../../../views/shared/jwtInterceptor";
import { setCookieItemWithExpiry, isMoreThanOneDay } from "../../../views/shared/utils";
import { AxiosError } from 'axios';

declare global {
  interface Navigator {
    wakeLock: WakeLock;
  }

  interface WakeLock {
    request(type: "screen"): Promise<WakeLockSentinel>;
  }

  interface WakeLockSentinel {
    release(): Promise<void>;
  }
}

type StateObjectTypes = {
	dated: string;
	old: boolean;
};

type FailedComponent = 'Calibration' | 'Diagnostic' | 'Solvent Reference';

interface IntervalOption {
	id: number;
	interval_code: string;
}

const initializeStateWithLocalStorageDataAndCheckExpiryDate = (key: string): object => {
	const defaultState = { old: null, dated: "--/--/--" };
	const storedData = localStorage.getItem(key);
	if (storedData) {
		const parsedData = JSON.parse(storedData);
		return { ...parsedData, old: isMoreThanOneDay(parsedData?.dated) };
	}
	return defaultState;
};

// Add type for toast ID
type ToastId = ReturnType<typeof toast.loading>;



const SystemControlCenter = () => {

	const navigate = useNavigate();
	const [nextScheduledTime, setNextScheduledTime] = useState<string | null>(null);
	const [lastCompletedWorkflow, setLastCompletedWorkflow] = useState<string | null>(null);
	const [latestDiagnostic, setLatestDiagnostic] = useState<StateObjectTypes>(() => initializeStateWithLocalStorageDataAndCheckExpiryDate("latestDiagnostic") as StateObjectTypes);
	const [latestUnitCalibration, setLatestUnitCalibration] = useState<StateObjectTypes>(() => initializeStateWithLocalStorageDataAndCheckExpiryDate("latestUnitCalibration") as StateObjectTypes);
	const [latestSolventReference, setLatestSolventReference] = useState<StateObjectTypes>(() => initializeStateWithLocalStorageDataAndCheckExpiryDate("latestSolventReference") as StateObjectTypes);
	const [showModal, setShowModal] = useState(false);
	const [wakeLock, setWakeLock] = useState<WakeLockSentinel | null>(null);
	const [connections, setConnection] = useState([]);
	const isTablet = useMediaQuery({ maxWidth: 714, maxHeight: 1200 });
	const isTabletLandscape = useMediaQuery({ maxWidth: 1200, maxHeight: 714, orientation: 'landscape' });
	const [selectedConnection, setSelectedConnection] = useState(() => {
	const storedConnection = localStorage.getItem("selectedHardware");
		return storedConnection ? JSON.parse(storedConnection) : null;
	});
	const [deviceStatus, setDeviceStatus] = useState(false);
	const [diagnosticSuccess, setDiagnosticSuccess] = useState(() =>
    localStorage.getItem('systemHealth') === 'true' || false
  );
  const [calibrationSuccess, setCalibrationSuccess] = useState(() =>
    localStorage.getItem('systemHealth') === 'true' || false
  );
  const [solventSuccess, setSolventSuccess] = useState(() =>
    localStorage.getItem('systemHealth') === 'true' || false
  );

  // Update errorModal state type
  const [errorModal, setErrorModal] = useState({
    visible: false,
    title: '',
    message: '',
    failedComponent: '' as FailedComponent,
    retryCount: 0
  });

  // Update retryCountRef to match FailedComponent type
  const retryCountRef = useRef({
    'Calibration': 0,
    'Diagnostic': 0,
    'Solvent Reference': 0
  });

  // Add state for automation status
  // const [automationStatus, setAutomationStatus] = useState<boolean>(false);

  // Add state for switch
  const [switchChecked, setSwitchChecked] = useState(false);

  // Add state for automation execution status
  // const [automationExecutionStatus, setAutomationExecutionStatus] = useState<boolean>(false);

  const ErrorModal = () => (
    <CModal
      alignment="center"
      visible={errorModal.visible}
      onClose={() => setErrorModal(prev => ({ ...prev, visible: false }))}
    >
      <CModalHeader>
        <CModalTitle>{errorModal.title}</CModalTitle>
      </CModalHeader>
      <CModalBody>
        {errorModal.message}
      </CModalBody>
      <CModalFooter>
        <CButton
          color="secondary"
          onClick={() => setErrorModal(prev => ({ ...prev, visible: false }))}
        >
          Close
        </CButton>
        {retryCountRef.current[errorModal.failedComponent as FailedComponent] < 2 && (
          <CButton
            color="primary"
            onClick={() => {
              setErrorModal(prev => ({ ...prev, visible: false }));
              if (errorModal.failedComponent === 'Calibration') handleUnitCalibration();
              if (errorModal.failedComponent === 'Diagnostic') fetchDataCopy();
              if (errorModal.failedComponent === 'Solvent Reference') handleScanHardwareCopy();
            }}
          >
            Retry {errorModal.failedComponent}
          </CButton>
        )}
      </CModalFooter>
    </CModal>
  );

  const notifyAutomationServer = async (machineData: {
    machine_name: string | undefined;
    device_type: string;
    command: string;
  }) => {
    try {
      const userEmail = localStorage.getItem('UserEmail') || 'unknown';

      // Get current date in EST
      const estDate = new Date().toLocaleString("en-US", {
        timeZone: "America/New_York",
        month: 'numeric',
        day: 'numeric',
        year: 'numeric'
      });

      // Get current time in EST
      const estTime = new Date().toLocaleString("en-US", {
        timeZone: "America/New_York",
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: true
      });

      const payload = {
        ...machineData,
        date: estDate,
        time: estTime,
        user: userEmail,
        branch: 'main',
      };

      await axios.post(
        `${process.env.REACT_APP_POWER_AUTOMATION_API_URL}`,
        payload,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
    } catch (error) {
      console.error('Failed to notify automation server:', error);
    }
  };

  // Function to handle hardware scanning
  const handleScanHardware = async () => {
    let toastId = null;
    try {
      const shouldTakeNewSolventReference = await CustomDialog(
        <ScanResults
          question={"Is the pipetrain empty?"}
          yesOption={"Yes, take a new Reference"}
          centerYesButton={true}
          noOption={null}
        />,
        {
          title: "Scan",
        }
      );

      if (shouldTakeNewSolventReference) {
        toastId = toast.loading("Taking a New Solvent Reference...");

        // Notification with retry count
        await notifyAutomationServer({
          machine_name: selectedConnection?.machine_name,
          device_type: 'System Control Center',
          command: retryCountRef.current['Solvent Reference'] > 0
            ? `Reference started (Attempt ${retryCountRef.current['Solvent Reference'] + 1})`
            : 'Reference started',
        });

        const response = await jwtInterceoptor.post(
          `${process.env.REACT_APP_API_URL}/user/execute-command/?machine_name=${selectedConnection?.machine_name}&serial_number=${selectedConnection?.serial_number}&device_type=scanner&command=scan_hardware&scan_type=solvent`
        );

        setSolventSuccess(true);

        // Success notification
        await notifyAutomationServer({
          machine_name: selectedConnection?.machine_name,
          device_type: 'System Control Center',
          command: retryCountRef.current['Solvent Reference'] > 0
            ? `Completed Successful Reference (Attempt ${retryCountRef.current['Solvent Reference'] + 1})`
            : 'Completed Successful Reference'
        });

        // Reset retry count on success
        retryCountRef.current['Solvent Reference'] = 0;

        let latestSolventReference = JSON.stringify({
          ...response.data,
          dated: new Date(),
        });
        localStorage.setItem("latestSolventReference", latestSolventReference);
        setLatestSolventReference({ old: false, dated: new Date() + "" });

        setCookieItemWithExpiry("solvent_scan_id", response.data.scan_id);

        toast.update(toastId, {
          render: "Successfully took a new Solvent Reference",
          type: "success",
          isLoading: false,
          autoClose: 1000,
        });
      }
    } catch (error) {
      retryCountRef.current['Solvent Reference']++;
      setSolventSuccess(false);
      // Failure notification with retry count
      await notifyAutomationServer({
        machine_name: selectedConnection?.machine_name,
        device_type: 'System Control Center',
        command: retryCountRef.current['Solvent Reference'] > 1
          ? `Failed Reference (Attempt ${retryCountRef.current['Solvent Reference']})`
          : 'Failed Reference'
      });

      localStorage.removeItem("latestSolventReference");
      setLatestSolventReference({ old: true, dated: "--/--/--" });
      setErrorModal({
        visible: true,
        title: 'Solvent Reference Failed',
        message: retryCountRef.current['Solvent Reference'] >= 2
          ? 'Multiple Solvent Reference attempts failed. Please contact support to resolve the issue.'
          : 'Solvent Reference failed. Please ensure the system is properly connected and try again.',
        failedComponent: 'Solvent Reference',
        retryCount: retryCountRef.current['Solvent Reference']
      });
      if (toastId) {
        toast.update(toastId, {
          render: (
            <div>
                <p style={{fontSize: '14px'}}>Error taking a new Solvent Reference</p>
                <p style={{fontSize: '12px', marginTop: '-10px'}}>
                {error instanceof AxiosError ? error.response?.data?.message : 'An unknown error occurred'}
              </p>
            </div>
          ),
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
      }
    }
  };

  const handleScanHardwareCopy = useCallback(async () => {
    let toastId = null;
    try {
      toastId = toast.loading("Taking a New Solvent Reference...");

      // Starting notification with retry count
      await notifyAutomationServer({
        machine_name: selectedConnection?.machine_name,
        device_type: 'System Control Center',
        command: retryCountRef.current['Solvent Reference'] > 0
          ? `Reference started (Attempt ${retryCountRef.current['Solvent Reference'] + 1})`
          : 'Reference started'
      });

      const response = await jwtInterceoptor.post(
        `${process.env.REACT_APP_API_URL}/user/execute-command/?machine_name=${selectedConnection?.machine_name}&serial_number=${selectedConnection?.serial_number}&device_type=scanner&command=scan_hardware&scan_type=solvent`
      );

      // Success notification with retry count
      await notifyAutomationServer({
        machine_name: selectedConnection?.machine_name,
        device_type: 'System Control Center',
        command: retryCountRef.current['Solvent Reference'] > 0
          ? `Completed Successful Reference (Attempt ${retryCountRef.current['Solvent Reference'] + 1})`
          : 'Completed Successful Reference'
      });

      // Reset retry count on success
      retryCountRef.current['Solvent Reference'] = 0;

      let latestSolventReference = JSON.stringify({
        ...response.data,
        dated: new Date(),
      });
      localStorage.setItem("latestSolventReference", latestSolventReference);
      setLatestSolventReference({ old: false, dated: new Date() + "" });
      setSolventSuccess(true);
      setCookieItemWithExpiry("solvent_scan_id", response.data.scan_id);

      toast.update(toastId, {
        render: "Successfully took a new Solvent Reference",
        type: "success",
        isLoading: false,
        autoClose: 1000,
      });
    } catch (error) {
      retryCountRef.current['Solvent Reference']++;

      // Failure notification with retry count
      await notifyAutomationServer({
        machine_name: selectedConnection?.machine_name,
        device_type: 'System Control Center',
        command: retryCountRef.current['Solvent Reference'] > 1
          ? `Failed Reference (Attempt ${retryCountRef.current['Solvent Reference']})`
          : 'Failed Reference'
      });

      localStorage.removeItem("latestSolventReference");
      setLatestSolventReference({ old: true, dated: "--/--/--" });
      setSolventSuccess(false);
      setErrorModal({
        visible: true,
        title: 'Solvent Reference Failed',
        message: retryCountRef.current['Solvent Reference'] >= 2
          ? 'Multiple Solvent Reference attempts failed. Please contact support to resolve the issue.'
          : 'Solvent Reference failed. Please ensure the system is properly connected and try again.',
        failedComponent: 'Solvent Reference',
        retryCount: retryCountRef.current['Solvent Reference']
      });
      if (toastId) {
        toast.update(toastId, {
          render: (
            <div>
                <p style={{fontSize: '14px'}}>Error taking a new Solvent Reference</p>
                <p style={{fontSize: '12px', marginTop: '-10px'}}>
                {error instanceof AxiosError ? error.response?.data?.message : 'An unknown error occurred'}
              </p>
            </div>
          ),
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
      }
    }
  }, [selectedConnection]);

  const fetchData = async () => {
    const html = "Is the pipetrain CLEAR of solvent and sample?";
    const result = await CustomDialog(
      <ScanResults
        yesOption={"Yes, run a new Diagnostic Scan."}
        noOption={null}
        centerYesButton={true}
        question={html}
      />,
      {
        title: "Scan",
      }
    );
    if (!result) {
      return;
    }

    let toastId = toast.loading("Running System Diagnostic...");
    try {
      // Starting notification with retry count
      await notifyAutomationServer({
        machine_name: selectedConnection?.machine_name,
        device_type: 'System Control Center',
        command: retryCountRef.current['Diagnostic'] > 0
          ? `Diagnostic started (Attempt ${retryCountRef.current['Diagnostic'] + 1})`
          : 'Diagnostic started'
      });

      const lampResponse = await jwtInterceoptor.post(
        `${process.env.REACT_APP_API_URL}/user/execute-command/?machine_name=${selectedConnection?.machine_name}&serial_number=${selectedConnection?.serial_number}&device_type=scanner&command=diagnostic`,
        "diagnostic_type=lamp",
        { headers: { "Content-Type": "application/x-www-form-urlencoded" } }
      );

      if (lampResponse.status === 201) {
        setDiagnosticSuccess(true);
        const failedTests = Object.keys(lampResponse.data).filter(
          (lampKey) => lampResponse.data[lampKey].test_result === "Fail"
        );

        if (failedTests.length > 0) {
          // Failure notification with retry count
          await notifyAutomationServer({
            machine_name: selectedConnection?.machine_name,
            device_type: 'System Control Center',
            command: retryCountRef.current['Diagnostic'] > 1
              ? `Failed Diagnostic (Attempt ${retryCountRef.current['Diagnostic']})`
              : 'Failed Diagnostic'
          });
          toast.update(toastId, {
            render: "Please Clean the Flow Cell",
            type: "error",
            isLoading: false,
            autoClose: 3000,
          });
        } else {
          // Success notification with retry count
          await notifyAutomationServer({
            machine_name: selectedConnection?.machine_name,
            device_type: 'System Control Center',
            command: retryCountRef.current['Diagnostic'] > 0
              ? `Completed Successful Diagnostic (Attempt ${retryCountRef.current['Diagnostic'] + 1})`
              : 'Completed Successful Diagnostic'
          });

          // Reset retry count on success
          retryCountRef.current['Diagnostic'] = 0;

          let latestDiagnostic = JSON.stringify({ dated: new Date() });
          localStorage.setItem("latestDiagnostic", latestDiagnostic);
          setLatestDiagnostic({ old: false, dated: new Date() + "" });
          toast.update(toastId, {
            render: "Diagnostics Ran Successfully",
            type: "success",
            isLoading: false,
            autoClose: 1000,
          });
        }
      }
    } catch (error) {
      retryCountRef.current['Diagnostic']++;
      setDiagnosticSuccess(false);
      // Failure notification with retry count
      await notifyAutomationServer({
        machine_name: selectedConnection?.machine_name,
        device_type: 'System Control Center',
        command: retryCountRef.current['Diagnostic'] > 1
          ? `Failed Diagnostic (Attempt ${retryCountRef.current['Diagnostic']})`
          : 'Failed Diagnostic'
      });

      console.error("Error fetching data:", error);
      setLatestDiagnostic({ old: true, dated: "--/--/--" });
      setErrorModal({
        visible: true,
        title: 'Diagnostics Failed',
        message: retryCountRef.current['Diagnostic'] >= 2
          ? 'Please contact support to resolve the issue.'
          : `${(error instanceof AxiosError) ? error.response?.data?.message : 'An unknown error occurred'}. Please contact support for help`,
        failedComponent: 'Diagnostic',
        retryCount: retryCountRef.current['Diagnostic']
      });
      toast.update(toastId, {
        render: (
          <div>
            <p style={{fontSize: '14px'}}>Error Running the Diagnostics</p>
            <p style={{fontSize: '12px', marginTop: '-10px'}}>
              {error instanceof AxiosError ? error.response?.data?.message : 'An unknown error occurred'}
            </p>
          </div>
        ),
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }
  };

  const fetchDataCopy = useCallback(async () => {
    let toastId = toast.loading("Running System Diagnostic...");
    try {
      // Starting notification with retry count
      await notifyAutomationServer({
        machine_name: selectedConnection?.machine_name,
        device_type: 'System Control Center',
        command: retryCountRef.current['Diagnostic'] > 0
          ? `Diagnostic started (Attempt ${retryCountRef.current['Diagnostic'] + 1})`
          : 'Diagnostic started'
      });

      const lampResponse = await jwtInterceoptor.post(
        `${process.env.REACT_APP_API_URL}/user/execute-command/?machine_name=${selectedConnection?.machine_name}&serial_number=${selectedConnection?.serial_number}&device_type=scanner&command=diagnostic`,
        "diagnostic_type=lamp",
        { headers: { "Content-Type": "application/x-www-form-urlencoded" } }
      );

      if (lampResponse.status === 201) {
        setDiagnosticSuccess(true);
        const failedTests = Object.keys(lampResponse.data).filter(
          (lampKey) => lampResponse.data[lampKey].test_result === "Fail"
        );

        if (failedTests.length > 0) {
          // Failure notification with retry count
          await notifyAutomationServer({
            machine_name: selectedConnection?.machine_name,
            device_type: 'System Control Center',
            command: retryCountRef.current['Diagnostic'] > 1
              ? `Failed Diagnostic (Attempt ${retryCountRef.current['Diagnostic']})`
              : 'Failed Diagnostic'
          });
          toast.update(toastId, {
            render: "Please Clean the Flow Cell",
            type: "error",
            isLoading: false,
            autoClose: 3000,
          });
        } else {
          // Success notification with retry count
          await notifyAutomationServer({
            machine_name: selectedConnection?.machine_name,
            device_type: 'System Control Center',
            command: retryCountRef.current['Diagnostic'] > 0
              ? `Completed Successful Diagnostic (Attempt ${retryCountRef.current['Diagnostic'] + 1})`
              : 'Completed Successful Diagnostic'
          });

          // Reset retry count on success
          retryCountRef.current['Diagnostic'] = 0;
          let latestDiagnostic = JSON.stringify({ dated: new Date() });
          localStorage.setItem("latestDiagnostic", latestDiagnostic);
          setLatestDiagnostic({ old: false, dated: new Date() + "" });
          toast.update(toastId, {
            render: "Diagnostics Ran Successfully",
            type: "success",
            isLoading: false,
            autoClose: 1000,
          });
        }
      }
    } catch (error) {
      retryCountRef.current['Diagnostic']++;

      // Failure notification with retry count
      await notifyAutomationServer({
        machine_name: selectedConnection?.machine_name,
        device_type: 'System Control Center',
        command: retryCountRef.current['Diagnostic'] > 1
          ? `Failed Diagnostic (Attempt ${retryCountRef.current['Diagnostic']})`
          : 'Failed Diagnostic'
      });

      console.error("Error fetching data:", error);
      setLatestDiagnostic({ old: true, dated: "--/--/--" });
      setDiagnosticSuccess(false);
      setErrorModal({
        visible: true,
        title: 'Diagnostics Failed',
        message: retryCountRef.current['Diagnostic'] >= 2
          ? 'Please contact support to resolve the issue.'
          : `${(error instanceof AxiosError) ? error.response?.data?.message : 'An unknown error occurred'}. Please contact support for help`,
        failedComponent: 'Diagnostic',
        retryCount: retryCountRef.current['Diagnostic']
      });
      toast.update(toastId, {
        render: (
          <div>
            <p style={{fontSize: '14px'}}>Error Running the Diagnostics</p>
            <p style={{fontSize: '12px', marginTop: '-10px'}}>
              {error instanceof AxiosError ? error.response?.data?.message : 'An unknown error occurred'}
            </p>
          </div>
        ),
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }
  }, [selectedConnection]);

	const handleYesClick = () => {
		fetchData();
	};

	const fetchDevices = async () => {
		const user_connection = await jwtInterceoptor.get(
			`${process.env.REACT_APP_API_URL}/user/api/user-connection/`
		);
		return user_connection?.data?.results;
	};

	useEffect(() => {
		fetchDevices().then((data) => {
			if (data) {
				const currentUrl = window.location.href;
				if (currentUrl.includes('demo')) {
					const filteredData = data.filter(
						(device: { machine_name: string; }) => device.machine_name.toLowerCase() === 'dummy'
					);
					setConnection(filteredData);
				}else{
					setConnection(data);
				}
			}
		});
		//cleanup
		return () => {
			setConnection([]);
		};
	}, []);

	// Fix checkDeviceStatus to be less chatty
	const checkDeviceStatus = useCallback(async (id: number) => {
		let deviceStatus: boolean;
		let toastId: ToastId = toast.loading("Checking Hardware Status");

		try {
			const user_connection = await jwtInterceoptor.get(
				`${process.env.REACT_APP_API_URL}/user/api/user-connection/${id}/`
			);
			
			let response = user_connection.data;
			deviceStatus = response.status_active;
			setDeviceStatus(deviceStatus);
			
			// Only show toast on failure or initial connection
			if (!deviceStatus) {
				toast.error(`${response.machine_name} Disconnected`);
			} else {
				toast.dismiss(toastId);
			}
		} catch (error) {
			toast.error("Failed to check hardware status");
		}
	}, [setDeviceStatus]);

	const handleConnections = useCallback(() => {
		if (!selectedConnection) {
			toast.error("Select your Hardware");
			return;
		}
		checkDeviceStatus(selectedConnection.id);
	}, [selectedConnection, checkDeviceStatus]);

	useEffect(() => {
		if (selectedConnection) {
      console.log(selectedConnection, "++++++")
			handleConnections();
			localStorage.setItem(
				"selectedHardware",
				JSON.stringify({...selectedConnection, dated: new Date()})
			);
		}
	}, [handleConnections, selectedConnection]);

  const handleUnitCalibration = useCallback(async () => {
    var toastId = toast.loading("Calibrating...");
    try {
      // Starting notification with retry count
      await notifyAutomationServer({
        machine_name: selectedConnection?.machine_name,
        device_type: 'System Control Center',
        command: retryCountRef.current['Calibration'] > 0
          ? `Calibration started (Attempt ${retryCountRef.current['Calibration'] + 1})`
          : 'Calibration started'
      });

      const calibration = await jwtInterceoptor.post(
        `${process.env.REACT_APP_API_URL}/user/execute-command/?machine_name=${selectedConnection?.machine_name}&serial_number=${selectedConnection?.serial_number}&device_type=scanner&command=calibrate`
      );

      if (calibration?.status === 201) {
        setCalibrationSuccess(true);
        // Success notification with retry count
        await notifyAutomationServer({
          machine_name: selectedConnection?.machine_name,
          device_type: 'System Control Center',
          command: retryCountRef.current['Calibration'] > 0
            ? `Completed Successful Calibration (Attempt ${retryCountRef.current['Calibration'] + 1})`
            : 'Completed Successful Calibration'
        });

        // Reset retry count on success
        retryCountRef.current['Calibration'] = 0;

        toast.update(toastId, {
          render: "Successfully Calibrated",
          type: "success",
          isLoading: false,
          autoClose: 1000,
        });
        localStorage.setItem("latestUnitCalibration", JSON.stringify({ dated: new Date() }));
        setLatestUnitCalibration({
          dated: new Date() + "",
          old: isMoreThanOneDay(new Date()),
        });
      }
    } catch (error) {
      retryCountRef.current['Calibration']++;

      // Failure notification with retry count
      await notifyAutomationServer({
        machine_name: selectedConnection?.machine_name,
        device_type: 'System Control Center',
        command: retryCountRef.current['Calibration'] > 1
          ? `Failed Calibration (Attempt ${retryCountRef.current['Calibration']})`
          : 'Failed Calibration'
      });

      localStorage.removeItem("latestUnitCalibration");
      setLatestUnitCalibration({ old: true, dated: "--/--/--" });
      setCalibrationSuccess(false);
      setErrorModal({
        visible: true,
        title: 'Calibration Failed',
        message: retryCountRef.current['Calibration'] >= 2
          ? 'Multiple calibration attempts failed. Please contact support to resolve the issue.'
          : 'Calibration failed. Please ensure the system is properly connected and try again.',
        failedComponent: 'Calibration',
        retryCount: retryCountRef.current['Calibration']
      });
      toast.update(toastId, {
        render: (
          <div>
            <p style={{fontSize: '14px'}}>Error Calibrating Spectrometer</p>
            <p style={{fontSize: '12px', marginTop: '-10px'}}>
              {error instanceof AxiosError ? error.response?.data?.message : 'An unknown error occurred'}
            </p>
          </div>
        ),
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }
  }, [selectedConnection]);

  const notifyStatus = async (isStarting: boolean) => {
    try {
      const userEmail = localStorage.getItem('UserEmail') || 'unknown';

        // Get current date in EST
        const estDate = new Date().toLocaleString("en-US", {
          timeZone: "America/New_York",
          month: 'numeric',
          day: 'numeric',
          year: 'numeric'
        });

        // Get current time in EST
        const estTime = new Date().toLocaleString("en-US", {
          timeZone: "America/New_York",
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
          hour12: true
        });

        const payload = {
        machine_name: selectedConnection?.machine_name,
        device_type: 'System Control Center',
        command: isStarting ? 'Automation Started' : 'Automation Stopped',
        date: estDate,
        time: estTime,
        user: userEmail,
        branch: 'main',
      };

      const response = await axios.post(
        `${process.env.REACT_APP_POWER_AUTOMATION_API_URL}`,
        payload,
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );
      console.log(response, "response");
    } catch (error) {
      console.log(error, "error");
    }
  }

  const openScheduleAutomationModal = async (e: any) => {
    setSwitchChecked(e.target.checked);

    if (e?.target?.checked) {
      setShowModal(true);
    } else {
      try {
        if (selectedConnection?.serial_number) {
          await jwtInterceoptor.delete(
            `${process.env.REACT_APP_API_URL}/user/api/scheduler/${selectedConnection.serial_number}/`
          );
          
          // Clear all automation states
          setSwitchChecked(false);
          
          notifyStatus(false);
          toast.info("Automation disabled");
        }
      } catch (error) {
        setSwitchChecked(true); // Keep switch on if deletion fails
        console.error("Failed to disable automation:", error);
        toast.error("Failed to disable automation");
      }
    }
  };

  // Modify the schedule creation/update
  const handleSave = async (time: string, intervalId: number) => {
    if (!selectedConnection?.serial_number) {
      toast.error('No device selected');
      return;
    }

    try {
      // Format the time to include today's date
      const [hours, minutes] = time.split(':');
      const now = new Date();
      now.setHours(parseInt(hours, 10));
      now.setMinutes(parseInt(minutes, 10));
      now.setSeconds(0);
      now.setMilliseconds(0);

      const formattedDateTime = now.toISOString().slice(0, 19).replace('T', ' ');

      const scheduleData = {
        interval_id: intervalId,
        serial_number: selectedConnection.serial_number,
        start_time: formattedDateTime
      };

      // Create the schedule
      const response = await jwtInterceoptor.post(
        `${process.env.REACT_APP_API_URL}/user/api/scheduler/`,
        scheduleData
      );

      if (response.status === 201 || response.status === 200) {
        const taskResponse = await jwtInterceoptor.get(
          `${process.env.REACT_APP_API_URL}/user/api/scheduler/${selectedConnection.serial_number}/`
        );

        setNextScheduledTime(taskResponse.data.start_time ? 
          formatScheduleTime(taskResponse.data.start_time) : 
          "Not scheduled"
        );
        setLastCompletedWorkflow(taskResponse.data.last_run_at || "Never");
        setSwitchChecked(true);
        
        toast.success('Schedule saved successfully');
        setShowModal(false);
      }
    } catch (error) {
      console.error('Failed to save schedule:', error);
      toast.error('Failed to save schedule');
    }
  };

	// First, define requestWakeLock and releaseWakeLock with useCallback
	const requestWakeLock = useCallback(async () => {
		try {
			const wakeLock = await navigator.wakeLock.request('screen');
			setWakeLock(wakeLock);
			console.log('Wake Lock is active');
		} catch (err: any) {
			console.error(`${err.name}, ${err.message}`);
		}
	}, []);  // Empty dependency array since it doesn't depend on any state/props

	const releaseWakeLock = useCallback(async () => {
		if (wakeLock) {
			await wakeLock.release();
			setWakeLock(null);
			console.log('Wake Lock has been released');
		}
	}, [wakeLock]);  // Only depends on wakeLock state

	// If you need wake lock functionality, add this effect:
	useEffect(() => {
		document.addEventListener('visibilitychange', () => {
			if (document.visibilityState === 'visible') {
				requestWakeLock();
			} else {
				releaseWakeLock();
			}
		});
	}, [requestWakeLock, releaseWakeLock]);

  // Update the periodic check
  useEffect(() => {
    if (!selectedConnection?.serial_number) return;

    const checkSchedule = async () => {
      try {
        const response = await jwtInterceoptor.get(
          `${process.env.REACT_APP_API_URL}/user/api/scheduler/${selectedConnection.serial_number}/`
        );
        
        if (response.data) {
          // Calculate next run from last_run_at + interval (1 day)
          const lastRun = new Date(response.data.last_run_at);
          const nextRun = new Date(lastRun);
          nextRun.setDate(nextRun.getDate() + 1);
          
          setNextScheduledTime(formatScheduleTime(nextRun.toISOString()));

          // Get current time for 24-hour comparison
          const now = new Date();
          const twentyFourHoursAgo = new Date(now.getTime() - (24 * 60 * 60 * 1000));

          // Update diagnostic status
          setLatestDiagnostic({
            old: new Date(response.data.diagnostic_dated) < twentyFourHoursAgo,
            dated: response.data.diagnostic_dated || "--/--/--"
          });

          // Update calibration status
          setLatestUnitCalibration({
            old: new Date(response.data.calibration_dated) < twentyFourHoursAgo,
            dated: response.data.calibration_dated || "--/--/--"
          });

          // Update reference status
          setLatestSolventReference({
            old: new Date(response.data.reference_dated) < twentyFourHoursAgo,
            dated: response.data.reference_dated || "--/--/--"
          });

          if (response.data.last_error) {
            console.log('Automation error:', response.data.last_error);
            setLastCompletedWorkflow(`Error: ${response.data.last_error}`);
          } else {
            setLastCompletedWorkflow(response.data.last_run_at ? 
              formatScheduleTime(response.data.last_run_at) : 
              "Never"
            );
          }
        }
      } catch (error) {
        console.error('Failed to check schedule:', error);
      }
    };

    checkSchedule();
    const intervalId = setInterval(checkSchedule, 60000);
    return () => clearInterval(intervalId);
  }, [selectedConnection?.serial_number]);

  useEffect(() => {
    console.log('diagnosticSuccess updated:', diagnosticSuccess);
    console.log('calibrationSuccess updated:', calibrationSuccess);
    console.log('solventSuccess updated:', solventSuccess);
    localStorage.setItem('systemHealth',
      (diagnosticSuccess && calibrationSuccess && solventSuccess) ? 'true' : 'false'
    );
  }, [diagnosticSuccess, calibrationSuccess, solventSuccess]);

  const [intervals, setIntervals] = useState<IntervalOption[]>([]);

  useEffect(() => {
    const fetchIntervals = async () => {
      try {
        console.log('Fetching intervals from:', `${process.env.REACT_APP_API_URL}/user/api/scheduler/interval/`);
        
        const response = await jwtInterceoptor.get(
          `${process.env.REACT_APP_API_URL}/user/api/scheduler/interval/`
        );
        
        console.log('Response:', response.data);
        
        if (response.data?.intervals) {
          const formattedIntervals = response.data.intervals.map((interval: IntervalOption) => ({
            id: interval.id,
            interval_code: interval.interval_code
          }));
          console.log('Formatted intervals:', formattedIntervals);
          setIntervals(formattedIntervals);
        } else {
          console.error('Invalid response structure:', response.data);
          toast.error("Invalid interval data received from server");
        }
      } catch (error) {
        console.error("Full error object:", error);
        if (error instanceof AxiosError) {
          console.error("Error response:", error.response?.data);
          console.error("Error status:", error.response?.status);
          toast.error(`API Error: ${error.response?.status} - ${error.response?.data?.message || error.message}`);
        } else {
          toast.error("Failed to connect to server");
        }
      }
    };

    fetchIntervals();
  }, []);

  // Add a helper function to format the date
  const formatScheduleTime = (dateTimeStr: string) => {
    if (!dateTimeStr) return "Not scheduled";
    const date = new Date(dateTimeStr);
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    });
  };

	return (

    <CContainer>
      <h1
        style={{
          fontWeight: 700,
          fontSize: isTablet ? "20px" : isTabletLandscape ? "20px" : "25px",
          lineHeight: isTablet ? "30px" : isTabletLandscape ? "30px" : "43.57px",
          color: "#000000",
          marginBottom: isTablet ? "10px" : isTabletLandscape ? "10px" : "20px",
          textAlign: "center",
          fontFamily: "Expansiva, sans-serif",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        System Control Center
      </h1>

      <div className="mb-2">
        <div style={{
          color: '#1488f5',
          fontSize: isTablet ? '12px' : '14px',
          fontWeight: '600',
          fontFamily: 'Inter'
        }}>
          <CFormSwitch
            label="Manual / Automated"
            onChange={openScheduleAutomationModal}
            checked={switchChecked}
            className="d-flex justify-content-center align-items-center gap-2"
            style={{ 
              backgroundColor: switchChecked ? '#1488f5' : '#d0d5f7',
              transform: 'scale(1.5)', 
              marginRight: '10px'
            }}
          />
          {/* {automationExecutionStatus && (
            <div style={{ 
              marginTop: '5px',
              color: '#dc3545',
              fontSize: '12px'
            }}>
              {automationExecutionStatus}
            </div>
          )} */}
        </div>
      </div>

      <ScheduleAutomationModal
        show={showModal}
        onClose={() => setShowModal(false)}
        onSave={handleSave}
        intervals={intervals}
      />

      <div
        className="d-flex justify-content-center align-items-center gap-3 p-2"
        style={{
          ...(isTabletLandscape ? {
            fontSize: '18px',
            marginTop: '-10px'
          } : isTablet ? {
            fontSize: '18px',
            marginTop: '-5px'
          } : {})
        }}
      >
        <div style={{ fontSize: isTablet ? "12px" : "14px" }}>
          Next Scheduled Time:
          <span style={{
            fontSize: isTablet ? "12px" : "14px",
            marginLeft: "5px",
            fontWeight: 700,
            lineHeight: isTablet ? "30px" : "43.57px",
            color: "#000000",
            marginBottom: isTablet ? "10px" : "20px",
            fontFamily: "Expansiva, sans-serif",
          }}>
            {nextScheduledTime || "Not scheduled"}
          </span>
        </div>
        <div style={{ fontSize: isTablet ? "12px" : "14px" }}>
          Last Completed Workflow:
          <span style={{
            marginLeft: "5px",
            fontWeight: 700,
            lineHeight: isTablet ? "30px" : "43.57px",
            color: "#000000",
            marginBottom: isTablet ? "10px" : "20px",
            fontFamily: "Expansiva, sans-serif",
          }}>
            {lastCompletedWorkflow || "Never"}
          </span>
        </div>
      </div>

      <CContainer>
        <CRow
          className={`${isTabletLandscape ? 'flex-nowrap justify-content-start' : 'justify-content-center'}`}
        >
          <CCol
            xs={isTablet ? 'auto' : true}
            style={{
              // width: isTabletLandscape ? '220px' : 'auto',
              marginBottom: '1rem',
              padding: '0.5rem',
              maxWidth: isTabletLandscape ? '280px' : '350px' // Control max width of cards
            }}
          >
            <SelectYourHardware
              selectedConnection={selectedConnection}
              setSelectedConnection={setSelectedConnection}
              connections={connections}
              deviceStatus={deviceStatus}
              handleConnections={handleConnections}
            />
          </CCol>
          <CCol
            xs={isTablet ? 'auto' : true}
            style={{
              // width: isTabletLandscape ? '220px' : 'auto',
              marginBottom: '1rem',
              padding: '0.5rem',
              maxWidth: isTabletLandscape ? '280px' : '350px'
            }}
          >
            <Diagnostic
              latestDiagnostic={latestDiagnostic}
              handleYesClick={handleYesClick}
            />
          </CCol>
          <CCol
            xs={isTablet ? 'auto' : true}
            style={{
              // width: isTabletLandscape ? '220px' : 'auto',
              marginBottom: '1rem',
              padding: '0.5rem',
              maxWidth: isTabletLandscape ? '280px' : '350px'
            }}
          >
            <Calibration
              latestUnitCalibration={latestUnitCalibration}
              handleUnitCalibration={handleUnitCalibration}
            />
          </CCol>
          <CCol
            xs={isTablet ? 'auto' : true}
            style={{
              // width: isTabletLandscape ? '220px' : 'auto',
              marginBottom: '1rem',
              padding: '0.5rem',
              maxWidth: isTabletLandscape ? '280px' : '350px'
            }}
          >
            <SolventReference
              latestSolventReference={latestSolventReference}
              handleScanHardware={handleScanHardware}
            />
          </CCol>
        </CRow>
      </CContainer>

      <CRow>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            ...(isTabletLandscape ? {
              marginTop: '-2rem'
            } : {})
          }}
        >
          <CButton
            variant="ghost"
            type="submit"
            style={{
              backgroundColor:
                !latestUnitCalibration?.old &&
                !latestDiagnostic?.old &&
                !latestSolventReference?.old
                  ? "#3AC90A"
                  : "#D8D8D8",
              color: "#000000",
              fontWeight: "600",
              height: "45px",
              fontSize: isTablet ? "12px" : "14px",
              fontFamily: "Inter",
              width: "30%",
              marginTop: isTablet ? "-1rem" : "20px",
            }}
            onClick={() => navigate("/ExtractoPredictionDashboard")}
          >
            Start Measuring Sample
          </CButton>
        </div>
      </CRow>
      <ErrorModal />
    </CContainer>
	);
};

export default SystemControlCenter;
