import React, { useState } from "react";
import {
  CModal,
  CButton,
  CForm,
  CFormLabel,
  CFormSelect,
  CFormInput
} from '@coreui/react';
import { useMediaQuery } from "react-responsive";
import { toast } from 'react-toastify';

interface IntervalOption {
  id: number;
  interval_code: string;  // Changed to match backend
}

interface ScheduleAutomationModalProps {
  show: boolean;
  onClose: () => void;
  onSave: (time: string, intervalId: number) => void;
  intervals: IntervalOption[];
}

interface FormErrors {
  time?: string;
  interval?: string;
}

const ScheduleAutomationModal: React.FC<ScheduleAutomationModalProps> = ({
  show,
  onClose,
  onSave,
  intervals,
}) => {
  const [selectedTime, setSelectedTime] = useState('');
  const [selectedInterval, setSelectedInterval] = useState<number>(0); // Changed to 0 for initial state
  const [errors, setErrors] = useState<FormErrors>({});
  const isTablet = useMediaQuery({ maxWidth: 992 });

  const handleSave = async () => {
    const newErrors: FormErrors = {};
    
    if (!selectedTime) {
      newErrors.time = "Please set the time";
    }
    if (!selectedInterval || selectedInterval === 0) {
      newErrors.interval = "Please select an interval";
    }
    
    setErrors(newErrors);

    // Only proceed if no errors
    if (Object.keys(newErrors).length === 0) {
      try {
        await onSave(selectedTime, selectedInterval);
        // Reset form
        setSelectedTime('');
        setSelectedInterval(0);
      } catch (error) {
        console.error('Failed to save schedule:', error);
        toast.error('Failed to save schedule');
      }
    }
  };

  return (
    <CModal visible={show} onClose={onClose} alignment="center">
      <header className="p-4">
        <h5>Set Automation Schedule</h5>
      </header>
      <main className="p-4" style={{ marginTop: '-30px' }}>
        <CForm>
          <div className="mb-3">
            <CFormLabel htmlFor="automationTime">Time</CFormLabel>
            <CFormInput
              type="time"
              id="automationTime"
              value={selectedTime}
              onChange={(e) => setSelectedTime(e.target.value)}
              required
              style={{
                ...(isTablet && {
                  height: '35px',
                  fontSize: '14px'
                })
              }}
            />
            {errors?.time && (
              <p className="error">{errors.time}</p>
            )}
          </div>

          <div className="mb-3">
            <CFormLabel htmlFor="automationInterval">Interval</CFormLabel>
            <CFormSelect
              id="automationInterval"
              value={selectedInterval}
              onChange={(e) => setSelectedInterval(Number(e.target.value))}
              required
            >
              <option value="0">Select an interval</option>
              {intervals?.map((interval) => (
                <option 
                  key={interval.id} 
                  value={interval.id}
                >
                  {interval.interval_code}  {/* Changed to use interval_code */}
                </option>
              ))}
            </CFormSelect>
            {errors?.interval && (
              <p className="error" style={{ color: 'red', fontSize: '0.8rem' }}>
                {errors.interval}
              </p>
            )}
          </div>
        </CForm>
      </main>
      <footer className="d-flex justify-content-center p-4" style={{ marginTop: '-30px' }}>
        <CButton color="primary" onClick={handleSave}>
          Save
        </CButton>
      </footer>
    </CModal>
  );
};

export default ScheduleAutomationModal;
